import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplateV2 from "../templates/RegionyTemplateV2";
import styled from "styled-components";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Cena obsahuje vyzvednutí zesnulého kdekoliv v daném městě, úpravu těla, domluvenou formu pohřbu, převezení do krematoria, samotný žeh a následné uložení do dočasné urny nebo jiné, kterou si vyberete. V rámci balíčku Péče+ (<a href="/pohrebni-sluzba-praha/">Praha</a> a <a href="/pohrebni-sluzba-brno/">Brno</a>) vám doručíme <a href="/poradna/kremacni-popel/">urnu</a> do vlastních rukou kamkoliv po regionu a jeho blízkém okolí. V ceně této služby je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a>, digitální pozůstalost, tisk a doručení parte a samozřejmě i možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu. S poplatky navíc můžete počítat u některých regionů v případě: akutního výjezdu nebo pokud má zesnulý více než 100kg.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];


const PohrebniSluzbaInner = styled.div`

  .leftJumbo {
    h1 {
      @media (max-width: 530px) {
        font-size: 40px !important;
      }

      @media (max-width: 430px) {
        margin-bottom: 5px !important;
        font-size: 37px !important;
      }

      @media (max-width: 340px) {
        font-size: 33px !important;
      }
    }
  }
`;


const PohrebniSluzba = ({ data }) => {

  return (
    <StaticQuery
      query={graphql`
          query {
            jumboObrazek: file(relativePath: { eq: "jumboObrazek.png" }) {
              childImageSharp {
                fixed(width: 460) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }

            jumboObrazekMobile: file(relativePath: { eq: "jumboObrazekMobile.png" }) {
              childImageSharp {
                fixed(width: 460) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
      `}
      render={data => ( <PohrebniSluzbaInner>
        <RegionyTemplateV2
          name="Pohřební služba"
          slug="pohrebni-sluzba"
          faqData={faqData}
          isMainPage
          rychleOdkazy

          seoTitle="Pohřební služba Goodbye.cz - Empatická & Dostupná (Celá ČR)"
          seoDesc="Nabízíme pohřební služby (Celá ČR: Praha, Brno, atd.): pohřeb s obřadem, kremace bez obřadu. Vše naplánujete z bezpečí domova. A za transparentní ceny."
          ratingCount={475}

          h1="Pohřební služba"
          perex={<>Naplánujeme s vámi jedinečný pohřeb z bezpečí domova a za předem stanovenou cenu. Přesně takové rozloučení, jaké by si váš blízký přál.<br/><br/>Postaráme se o vše potřebné, abyste se vy a vaše rodina mohli věnovat v těchto těžkých časech především jeden druhému.</>}
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          reviewDesc="Nejlépe hodnocená pohřební služba v ČR za rok 2024"

          sklonovaneMesto=""

          introH2="V čem jsme jiní než ostatní pohřební služby v Čechách?"
          introText={<>Řešení úmrtí blízkého je možná ta nejtěžší situace v životě člověka: plná stresu, zmatku a smutku. Sami jsme se v minulosti setkali s neempatickým přístupem několika pohřebních služeb. Vypravení pohřbu bylo chladné, neosobní a vždy za neúměrně vysokou cenu. To jsme se rozhodli změnit.<br/><br/>Založili jsme Goodbye s důrazem na osobní přístup a transparentnost – jako pohřební službu, kterou bychom s klidným srdcem nabídli vlastní rodině a přátelům. U nás nemusíte nikam chodit. Celý pohřeb si nezávazně sestavíte přes internet a rovnou se dozvíte konečnou cenu. Náš empatický tým odborníků vám následně pomůže uspořádat jedinečné poslední rozloučení v <Link to="/pohreb-s-obradem/">tradiční</Link> či <Link to="/netradicni-pohreb/">alternativní</Link> podobě pohřbu.</>}

          procesDesc="V klidu vás provedeme celým procesem a zařídíme, co je potřeba. Věříme, že si každý zaslouží důstojné poslední rozloučení. Uděláme vše pro to, abychom vám a vaší rodině pomohli uspořádat to pravé."
          procesText1={<>Zajistíme převoz vašeho blízkého do našeho zázemí ve vašem regionu. Může se jednat i o <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">akutní vyzvednutí z domu</Link>, nemocnice nebo jiné pohřební služby.</>}
          procesText2={<>Pomůžeme vám naplánovat osobní rozloučení podle vašich přání. Může se jednat o <Link to="/pohreb-s-obradem/">tradiční pohřeb v krematoriu</Link>, <Link to="/kremace-bez-obradu/">kremaci bez obřadu</Link> nebo i <Link to="/netradicni-pohreb/">netradiční variantu</Link>.</>}
          procesText3={<>Zajistíme pietní a důstojnou <Link to="/poradna/kremace-zajimavosti/">kremaci</Link> v našem partnerském krematoriu. Mezi tím za vás vyřešíme veškerou dokumentaci a <Link to="/slovnik-pojmu/umrtni-list/">úmrtní list</Link> na matrice.</>}
          procesText4={<>Urnu si u nás můžete vyzvednout nebo vám ji přivezeme až domů. Popel potom můžete <Link to="/slovnik-pojmu/rozptyl-vsyp-popela/">rozptýlit</Link>, <Link to="/slovnik-pojmu/hrob/">uložit do kolumbária</Link> či například <Link to="/poradna/kremacni-popel/">zasadit strom</Link>. Je to jen na vás.</>}

          kremaceText="Postaráme se o vyzvednutí a následnou kremaci. Urnu vám potom předáme. Forma rozloučení je potom jen na vás."
          kremaceCena="12 990"
          kremaceSluzby={[
            "Sjednání za pár minut přes internet",
            "Vyřízení veškerých dokumentů",
            "Tvorba parte",
          ]}

          pohrebCena="19 990"

          mestoH2="Nadstandartní péče ve všech ohledech"
          mestoText={<>
            Věříme, že si každý člověk zaslouží osobní rozloučení. Oproti ostatním pohřebním službám však nerozlišujeme, jestli proběhne ve velké síni v krematoriu či pouze doma v rodinném kruhu. Společně vám pomůžeme vymyslet to pravé.
            <br/><br/>
            Mimo organizaci samotného pohřbu nabízíme i řadu dalších služeb, památečních předmětů, květin a urnen, které si k pohřbu můžete přiobjednat. Vše si můžete vybrat přímo při objednávce pohřbu v naší aplikaci nebo po telefonu s naší operátorkou.
            <br/><br/>
            Jsme vám také schopni asistovat v záležitostech jako např. odprodej a ocenění nemovitosti, převod digitálních účtů, rušení předplatných nebo také propojení s rodinými mediátory a terapeuty.
          </>}

          featuresH2="Profesionalita a důstojnost je pro nás na prvním místě"
          featuresText1={<>S námi celý pohřeb naplánujete z bezpečí domova. Nemusíte nikam chodit. Vše si můžete v klidu rozmyslet a poradit se s rodinou.</>}
          featuresText2="Cena, na které se s naší pohřební službou domluvíte, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat."
          featuresText3={<>Ve většině regionů se o vše postaráme za vás: vyřízení dokumentů na matrice, osobní doručení urny nebo převod digitálních účtů.</>}

          seVsimPomuzemeText="Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení."
          
        />
      </PohrebniSluzbaInner>)}
    />
  );
}

export default PohrebniSluzba;


